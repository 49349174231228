/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/generic.services.ts" />
/// <reference path="../../services/queues.services.ts" />

class queuesCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', 'toastr'];
    showsubflows: boolean;
    genericServices: GenericServiceClass;
    queuesServices: QueuesServiceClass;
    toastrObj: any;
    queuesEntities: Array<any> = [];
    projectsJoin: Array<any> = [];
    limit: number = globalLoadData;
    currentQueue: String = "";
    queueItemsInprogressCount: any = 8888;

    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;
        this.collection = 'queues';
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.queuesServices = new QueuesServiceClass(this.service.socket, this.service.namespace)

        // this.basequery = '_type eq \'user\'';
        var user: any = this.socketService.user;
        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == queuingUsersRoleId)) {
            this.$location.path("/");
            this.$scope.$apply();
        }
        this.socketService.onSignedin(() => {
            this.basequery = ''
            this.getQueuesPagedData();


        });
    }
    orderby(field: string) {
        if (field == this.order.field) {
            this.order.reverse = !this.order.reverse;
        }
        else {
            this.order.field = field;
            this.order.reverse = false;
        }
        this.entities = [];
        this.page = 1;
        //this.loaddata();
        this.getQueuesPagedData()
    }
    async getQueuesPagedData() {
        this.loading = true;
        clearError(this.$scope);
        this.service = this.socketService.namespace;

        if (!this.genericServices.isConnected()) return;

        let query: any = this.basequery;
        if (this.searchstring == null || this.searchstring == '') {
            if (query) {
                if (query.name) {
                    delete query.name;
                }
                if (query.$or) {
                    delete query.$or;
                }
            }
        }

        // prepare search query 
        if (this.searchstring !== '') {
            query += "contains(queuename,'" + this.searchstring + "')"
        }

        try {
            let projection = { 'queuename': 1, '_created': 1, '_createdby': 1 };
            var Result: any = await this.queuesServices.getQueuesPageService(query, projection, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection, false);
            this.queuesEntities = Result.items;
            //to get the workflows for the loaded projects ids
            var QueuesIds = this.queuesEntities.map(P => P._id);
            var myquery: any = {};

            myquery.QueueId = { $in: QueuesIds };
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);
            if (this.pageCount < this.page) {
                this.page = this.pageCount;
            }
            this.loading = false;
            this.scrollloading = (this.queuesEntities.length > 0);
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    async showModal(id) {
        this.confirm = id;
        this.queueItemsInprogressCount = await this.queuesServices.getQueueItemsIngrogressCount(id);
        ($('#exampleModal') as any).modal('show'); 
        this.$scope.$apply();
        this.loaddata();
    }
    async deleteQueue(id) {
        this.loading = true;
        try {
            await this.queuesServices.deletQueueService(id);
            this.getQueuesPagedData();
            this.loading = false;
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    async deleteQueueItem(id) {

        this.loading = true;
        try {
            await this.queuesServices.deletQueueItemService(id);
            this.getQueuesPagedData();
            this.loading = false;
        } catch (err) {
            handleError(this.$scope, err);
        }

    }
    searchInQueues() {
        this.loading = true;
        this.entities = [];
        this.page = 1;
        //this.loaddata();
        this.getQueuesPagedData();
        this.loading = false;
    }
    routeToQueueItems(id, name) {
        this.currentQueue = name;
        localStorage.setItem('currentQueueName', name);
        this.$location.path("/queueItems/" + id + "/" + name);

    }
}

class editQueueCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', '$timeout', '$routeParams', 'toastr'];
    id: string;
    model: any;
    ChangePasswordForm: any;
    loading: boolean = false;
    toastrObj: any;
    DisabledEdit = false;
    DisableEditIsActive = false;
    genericServices: GenericServiceClass;
    queuesServices: QueuesServiceClass;

    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public $timeout,
        public $routeParams,
        public toastr
    ) {
        this.id = this.$routeParams.id
        this.toastrObj = toastr;
        var user: any = this.socketService.user;
        this.genericServices  = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
        this.queuesServices = new QueuesServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == queuingUsersRoleId)) {
            this.$location.path("/");
            this.$scope.$apply();
        }
        this.socketService.onSignedin(() => {
                this.loaddata(this.id);
        });
    }
    async loaddata(id) {
        this.loading = true;
        var query = "_id eq '" + id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'queues');
            this.loading = false;
            if (items.length == 0) {
                // handleError(this.$scope, { error: { message: 'No such user found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such user found ' + id } }));
                return;
            }
            this.model = items[0];
            //  this.DisabledEdit = false;
            //if(this.model.ownerid || this.model.isOwner == true || this.model.CommunityLicense == true ){
            this.DisabledEdit = true;
            // }

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            this.focusfirstinput();
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));

        }
    }

    focusfirstinput() {
        //TODO try this function
        let data = this.model;
        if (!data)
            return;
        if (!data.ui)
            return;
        if (data.ui.length == 0)
            return;
        var name = null;
        for (var i = 0; i < data.ui.length; i++) {
            if (data.ui[i].readonly == false) {
                name = data.ui[i].name;
                break;
            }
        }
        if (name) {
            this.$timeout(() => {
                this.$scope.$broadcast(name, null);
            }, 50);
        }
    }
    async AddUpdateQueue() {
        this.loading = true;
        try {
            //TODO add check to check if queue already exists
            /*
            var nameQuery = "username eq '" + this.model.username + "'";
            var usernames = await this.genericServices.get(nameQuery, 1, 0, null, null, 'users');
            var mailQuery = "email eq '" + this.model.email + "'";
            var emails = await this.genericServices.get(mailQuery, 1, 0, null, null, 'users');
            */
            if (this.model._id) {
                // var userQuery = "_id eq '" + this.model._id + "'";
                // var user = await this.genericServices.get(userQuery, 1, 0, null, null, 'queues');

                await this.genericServices.put(this.model, 'queues');
                this.loading = false;
                this.$location.path("/queues");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }

                return;
            }
            else {
                await this.genericServices.post(this.model, 'queues');
                this.loading = false;
                this.$location.path("/queues");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }


            }

        }
        catch (err) {
            this.loading = false;
            this.toastrObj.error(GetErrorMessage(err));
            //handleError(this.$scope, err);
        }
    }


    async savemodel() {
        try {
            if (this.model && this.model.autoretry && this.model.maxretry <= 0) {
                this.toastrObj.error(GetErrorMessage('Please add maximum retry number'));
                return;
            }
            if (this.model._id) {
                await this.queuesServices.updateQueueService(this.model, 'queues');
                this.loading = false;
                this.$location.path("/queues");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else {
                await this.queuesServices.addQueueService(this.model, 'queues');
                this.loading = false;
                this.$location.path("/queues");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }




}

class addQueueCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', '$timeout', '$routeParams', 'toastr'];
    id: string;
    model: any;
    ChangePasswordForm: any;
    loading: boolean = false;
    toastrObj: any;
    DisabledEdit = false;
    DisableEditIsActive = false;
    genericServices: GenericServiceClass;
    queuesServices: QueuesServiceClass;

    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public $timeout,
        public $routeParams,
        public toastr
    ) { 
        this.toastrObj = toastr;
        var user: any = this.socketService.user;
        this.genericServices  = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
        this.queuesServices = new QueuesServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == queuingUsersRoleId)) {
            this.$location.path("/");
            this.$scope.$apply();
        }
        this.socketService.onSignedin(() => { 
                this.DisabledEdit = false;
                this.DisableEditIsActive = true;
                this.model = {
                    queuename: '',
                    description: '',
                    uniqueref: false,
                    autoretry: false,
                    maxretry: 1
                };
            
        });
    }
  

    
    async AddUpdateQueue() {
        this.loading = true;
        try {
            //TODO add check to check if queue already exists
            /*
            var nameQuery = "username eq '" + this.model.username + "'";
            var usernames = await this.genericServices.get(nameQuery, 1, 0, null, null, 'users');
            var mailQuery = "email eq '" + this.model.email + "'";
            var emails = await this.genericServices.get(mailQuery, 1, 0, null, null, 'users');
            */
            if (this.model._id) {
                // var userQuery = "_id eq '" + this.model._id + "'";
                // var user = await this.genericServices.get(userQuery, 1, 0, null, null, 'queues');

                await this.genericServices.put(this.model, 'queues');
                this.loading = false;
                this.$location.path("/queues");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }

                return;
            }
            else {
                await this.genericServices.post(this.model, 'queues');
                this.loading = false;
                this.$location.path("/queues");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }


            }

        }
        catch (err) {
            this.loading = false;
            this.toastrObj.error(GetErrorMessage(err));
            //handleError(this.$scope, err);
        }
    }


    async savemodel() {
        try {
            if (this.model && this.model.autoretry && this.model.maxretry <= 0) {
                this.toastrObj.error(GetErrorMessage('Please add maximum retry number'));
                return;
            }
            if (this.model._id) {
                await this.queuesServices.updateQueueService(this.model, 'queues');
                this.loading = false;
                this.$location.path("/queues");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else {
                await this.queuesServices.addQueueService(this.model, 'queues');
                this.loading = false;
                this.$location.path("/queues");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }




}

 


class queueItemsCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', '$routeParams', 'toastr'];
    queueId: string;
    showsubflows: boolean;
    genericServices: GenericServiceClass;
    queuesServices: QueuesServiceClass;
    toastrObj: any;
    queueItems: Array<any> = [];
    projectsJoin: Array<any> = [];
    limit: number = globalLoadData;
    currentQueue: string = '';
    itemData: any = {};
    selectedItem: string = '';
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public $routeParams,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;
        this.collection = 'queueitems';
        this.currentQueue = this.$routeParams.name;
        this.queueId = this.$routeParams.id
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.queuesServices = new QueuesServiceClass(this.service.socket, this.service.namespace)

        // this.basequery = '_type eq \'user\'';
        var user: any = this.socketService.user;
        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == queuingUsersRoleId)) {
            this.$location.path("/");
            this.$scope.$apply();
        }
        this.socketService.onSignedin(() => {
            this.basequery = ''
            this.getQueueItemsPagedData(this.queueId);
        });
    }
    orderby(field: string) {
        if (field == this.order.field) {
            this.order.reverse = !this.order.reverse;
        }
        else {
            this.order.field = field;
            this.order.reverse = false;
        }
        this.entities = [];
        this.page = 1;
        //this.loaddata();
        this.getQueueItemsPagedData(this.queueId)
    }
    GetItemsData(pageNumber: number) {
        this.page = pageNumber;
        this.getQueueItemsPagedData(this.queueId);

    }

    transactionRowsData: any = [];
    viewTransactions(item: any) {
        localStorage.setItem("currentItem", JSON.stringify(item))
        this.$location.path("/itemtransactions/" + item._id);
    }
    totalTransactionItems: number = 0;
    transactionsPagesize: number = 10;
    TransactionPage: number = 1;
    async getTransactionItemsPage() {

        try {
            let start = ((this.TransactionPage - 1) * this.transactionsPagesize);
            this.itemData.Rows = this.transactionRowsData.slice(start, start + 10); //await this.queuesServices.getQueueItemsPageService(query, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection, false);


            this.totalTransactionItems = this.transactionRowsData.length;
            this.pageCount = Math.ceil(this.transactionRowsData.length / this.transactionsPagesize);
            if (this.pageCount < this.TransactionPage) {
                this.TransactionPage = this.pageCount;
            }
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    async getQueueItemsPagedData(queueId: string) {
        this.loading = true;
        clearError(this.$scope);
        this.service = this.socketService.namespace;

        if (!this.genericServices.isConnected()) return;

        let query: any = 'QueueId eq \'' + queueId + "\'";
        if (this.searchstring == null || this.searchstring == '') {
            if (query) {
                if (query.name) {
                    delete query.name;
                }
                if (query.$or) {
                    delete query.$or;
                }
            }
        }

        // prepare search query 
        if (this.searchstring !== '') {
            query += " and contains(Name,'" + this.searchstring + "')"
        }

        try {
            var Result: any = await this.queuesServices.getQueueItemsPageService(query, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection, false);
            this.queueItems = Result.items;

            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);
            if (this.pageCount < this.page) {
                this.page = this.pageCount;
            }
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    async deleteQueueItem(id) {

        this.loading = true;
        try {
            await this.queuesServices.deletQueueItemService(id);
            await this.getQueueItemsPagedData(this.queueId);

        } catch (err) {
            handleError(this.$scope, err);
        }

    }
   
    searchInQueuesItems() {
        this.loading = true;
        this.entities = [];
        this.page = 1;
        this.getQueueItemsPagedData(this.queueId);
        this.loading = false;
    }
}


class itemTransactionsCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', '$routeParams', 'toastr'];
    queueId: string;
    showsubflows: boolean;
    genericServices: GenericServiceClass;
    queuesServices: QueuesServiceClass;
    transactionsData: any = [];
    transactionsHeaders: any = [];
    currentItem: any = {};
    currentQueueName = '';
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public $routeParams,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.collection = 'queueitems';
        this.queueId = this.$routeParams.id
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.queuesServices = new QueuesServiceClass(this.service.socket, this.service.namespace)


        var user: any = this.socketService.user;
        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == queuingUsersRoleId)) {
            this.$location.path("/");
            this.$scope.$apply();
        }

        this.currentItem = JSON.parse(localStorage.getItem('currentItem')!)
        this.currentQueueName = localStorage.getItem('currentQueueName')!;
        this.transactionRowsData = this.currentItem.Value;
        this.itemData.Headers = this.currentItem.Headers;
        this.getTransactionItemsPage()
    }
    routeToQueueItems() {
        this.$location.path("/queueItems/" + this.currentItem.QueueId + "/" + this.currentQueueName);

    }


    transactionRowsData: any = [];
    itemData: any = {};
    loadData() {
        this.loading = true;
        this.getTransactionItemsPage();
        this.loading = false;

    }
    totalTransactionItems: number = 0;
    transactionsPagesize: number = 10;
    TransactionPage: number = 1;
    async getTransactionItemsPage() {

        try {




            let start = ((this.TransactionPage - 1) * this.transactionsPagesize);
            this.itemData.Rows = this.transactionRowsData.slice(start, start + 10);


            this.totalTransactionItems = this.transactionRowsData.length;
            this.pageCount = Math.ceil(this.transactionRowsData.length / this.transactionsPagesize);
            if (this.pageCount < this.TransactionPage) {
                this.TransactionPage = this.pageCount;
            }
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }




}
